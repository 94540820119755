import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { getContactName } from '@/shared/utils/getContactName';

import { ContactDetails, LinkedHousehold, MainInformation } from '@modules/ContactComponents';
import BookmarkIcon from '@components/BookmarkIcon';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import TextWithTooltip from '@components/TextWithTooltip';

import { ContactInformationWrapper, HeaderWrapper, ContactDetailsHeader } from './styles';
import { LayoutWrapper, MainContactInformationLayoutWrapper } from '../styles';

import {
  BUSINESS_PROFILE_LINKS,
  CONTACT_DETAILS_TAB,
  HOUSEHOLD_PROFILE_LINKS,
  INDIVIDUAL_PROFILE_LINKS
} from '@constants/routes/contacts';
import { CONTACT_TYPES } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';

import { IdType } from '@/shared/types/commonTypes';

const BOOKMARK_BOX_PROPS = {
  height: '18px',
  marginRight: '10px'
};

export const ContactInformation = observer(() => {
  const contactDetailsOverview = useStore().ContactDetailsOverview;
  const {
    adviserData,
    contact,
    contactSpouse,
    updateFlags,
    updateRating,
  } = contactDetailsOverview;

  const modalStore = useStore().ModalStore;

  const goToLinkedContactOverview = useCallback((id: IdType, type: CONTACT_TYPES) => {
    routerStore.moveToContact({
      id,
      contactType: type,
      tab: CONTACT_DETAILS_TAB.overview,
    });
  }, []);

  const onBookmarkClick = useCallback((state: boolean) => {
    if(contact) {
      updateFlags({ id: contact.id }, state);
    }
  },[ updateFlags, contact ]);

  const onRankChange = useCallback((newRating: number) => {
    if(contact) {
      updateRating(contact.id, newRating);
    }
  }, [updateRating, contact]);

  const routerStore = useStore().RouterStore;

  const goToContactDetails = useCallback(() => {
    if(contact) {
      let subTab = '' as any;
      switch(contact.type) {
      case CONTACT_TYPES.BUSINESS:
        subTab = BUSINESS_PROFILE_LINKS.businessDetails;
        break;
      case CONTACT_TYPES.HOUSEHOLD:
        subTab = HOUSEHOLD_PROFILE_LINKS.householdDetails;
        break;
      default:
        subTab = INDIVIDUAL_PROFILE_LINKS.individualDetails;
        break;
      }
      routerStore.moveToContact({
        id: contact.id,
        contactType: contact.type,
        tab: CONTACT_DETAILS_TAB.profile,
        subTabProps: {
          contactType: contact.type,
          subTab: subTab
        }
      });  
    }
  }, [routerStore, contact]);

  const isBlocked = typeof contactSpouse?.editAllow === 'number' && !contactSpouse?.editAllow;

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  const onSpouseClick = useCallback(() => {
    if(contactSpouse) {
      if(isBlocked) {
        onOutOfSightClick();
      } else {
        routerStore.moveToContact({
          id: contactSpouse.id,
          contactType: CONTACT_TYPES.INDIVIDUAL,
          tab: CONTACT_DETAILS_TAB.overview,
        });
      }
    }
  }, [contactSpouse, routerStore]);

  const renderHeaderTitle = useMemo(() => {
    return(
      <HeaderWrapper>
        <BookmarkIcon
          isActive={ Boolean(contact?.flagged) }
          onStateChange={ onBookmarkClick }
          boxProps={ BOOKMARK_BOX_PROPS }
        />
        <TextWithTooltip text={ getContactName(contact) }/>
      </HeaderWrapper>
    );
  }, [contact, contact?.flagged]);

  const contactDetailsHeaderTitle = useMemo(() => {
    return (
      <ContactDetailsHeader>
        {contact?.type} Contact Information
      </ContactDetailsHeader>
    );
  }, [contact]);

  if(!contact) {
    return null;
  }

  return (
    <ContactInformationWrapper>
      <MainContactInformationLayoutWrapper $height={ '49%' }>
        <MainInformation
          adviserData={ adviserData }
          contact={ contact }
          contactSpouse={ contactSpouse }
          isBlocked={ isBlocked }
          isBorderBottom={ false }
          onRankChange={ onRankChange }
          onSpouseClick={ onSpouseClick }
          renderHeaderTitle={ renderHeaderTitle }
          size={ 'small' }
        />
      </MainContactInformationLayoutWrapper>
      <LayoutWrapper $height={ '51%' }>
        { contact.type !== CONTACT_TYPES.BUSINESS &&
          <LinkedHousehold
            currentContact={ contact }
            onClick={ goToLinkedContactOverview }
            onOutOfSightClick={ onOutOfSightClick }
          />
        }
        <ContactDetails
          contact={ contact }
          contactsDetails={ contact.contacts }
          headerTitle={ contactDetailsHeaderTitle }
          onViewAllClick={ goToContactDetails }         
        />
      </LayoutWrapper>
    </ContactInformationWrapper>
  );
});
