import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { CONTACT_DETAILS_SIDE_BAR_PANEL_TAB } from './data';
import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { useContactDetailsSideBarPanelLocalStore } from './store';

import { ContactDetails, Header, Notes, Tabs, ToDosTable } from './components';
import Preloader from '@components/Preloader';

import { ContactDetailsSideBarPanelProps } from './types';

import { Panel } from './styles';

export const ContactDetailsSideBarPanel = observer(({
  contactData,
  isOpen,
  onClose,
  onRefreshToDoTable
}: ContactDetailsSideBarPanelProps) => {

  const routerStore = useStore().RouterStore;
  const store = useContactDetailsSideBarPanelLocalStore();
  const { currentTab, init, isLoading, reset } = store;

  const { id, type } = contactData;
  
  const onContactNameClick = useCallback(() => {
    routerStore.moveToContact({
      id,
      contactType: type,
      tab: CONTACT_DETAILS_TAB.profile,
      subTabProps: {
        contactType: type,
      }
    });
  }, [routerStore, id, type]);

  useEffect(() => {
    init(id, onRefreshToDoTable);
    return reset;
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <Panel
      anchor={ 'right' }
      open={ isOpen }
    >
      {
        isLoading && <Preloader />
      }
      <Header
        onClose={ onClose }
        onContactNameClick={ onContactNameClick }
      />
      <Tabs/>

      { currentTab === CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.contactDetails &&
        <ContactDetails/>
      }

      { currentTab === CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.toDos &&
        <ToDosTable/>
      }

      { currentTab === CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.notes &&
        <Notes/>
      }
    </Panel>
  );
});
