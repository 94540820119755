import React, { useEffect, useState, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getInitNoteContactIdValue } from '@modules/NotesAndHistory/utils';
import { getValidationScheme } from './validationSchema';
import { NOTE_AND_HISTORY_TYPE } from '@modules/NotesAndHistory';
import { NOTE_TYPES } from '@constants/common';

import { GeneralAdditionalProps } from './components/GeneralAdditionalProps';
import { GeneralNoteTypes } from './components/GeneralNoteTypes';
import { Header } from '@modules/NotesAndHistory/components/Notes/components';
import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';

import { Tags, NoteText, TitleAndWith, TypeSelect, SaveButton } from './components';

import { ButtonWrapper, FieldsRow, TagsWrapper, TextEditorWrapper, Wrapper, } from './styles';


// TODO Ts migrate
export const AddEditNote = ({
  editNote,
  linkedContactData,
  names,
  noteAndHistoryType,
  noteContactOptions,
  onAddEditEnd,
  onAddEditStart,
  onCancelAddNoteClick,
  onEntityIdClick,
  onSaveNoteClick,
  predefinedTags,
}) => {
  const title = editNote ? 'Save Edits' : 'Add Note';

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: {
      errors
    }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(getValidationScheme(names)),
    defaultValues: {
      ...(
        editNote
          ? {
            ...editNote,
            noteText: editNote.noteText ?
              editNote.noteText.replaceAll(/markedTextStart/g, '').replaceAll(/markedTextEnd/g, '') : '',
            title: editNote.title.replaceAll(/markedTextStart/g, '').replaceAll(/markedTextEnd/g, ''),
            linkedItemId: editNote.linkedItemId,
            linkedItem: editNote.linkedItem
          }
          : {
            [names.noteType]: NOTE_TYPES.note,
            [names.noteContactId]: getInitNoteContactIdValue(linkedContactData),
            [names.title]: '',
            [names.noteText]: '',
            [names.tags]: [],
          }
      ) }
  });

  const [initLinkedItem, setInitLinkedItem] = useState(editNote && editNote.linkedItem ? editNote.linkedItem : null);

  const onLinkedItemChange = useCallback((linkedItem) => {
    setValue(names.linkedItem, linkedItem);
    setInitLinkedItem(linkedItem);
  }, [setValue]);

  const onSuccessSubmit = (data) => onSaveNoteClick(data);
  const onSaveClick = handleSubmit(onSuccessSubmit);

  useEffect(() => {
    onAddEditStart(editNote);
    return () => onAddEditEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Wrapper>
      <Header>
        <Header.Row>
          <Header.Tiltle>
            {title}
          </Header.Tiltle>
          <CustomIconButton
            onClick={ onCancelAddNoteClick }
            type={ ICON_BUTTON_TYPES.cross }
          />
        </Header.Row>
        <Header.Row>
          { noteAndHistoryType === NOTE_AND_HISTORY_TYPE.module ?
            <TypeSelect
              control={ control }
              name={ names.noteType }
            /> :
            <GeneralNoteTypes
              control={ control }
              name={ names.noteType }
            />
          }
        </Header.Row>
      </Header>

      <FieldsRow>
        { noteAndHistoryType === NOTE_AND_HISTORY_TYPE.module ?
          <TitleAndWith
            control={ control }
            noteContactIdName={ names.noteContactId }
            noteContactOptions={ noteContactOptions || [] }
            titleName={ names.title }
          /> :
          <GeneralAdditionalProps
            control={ control }
            currentLinkedContact={ linkedContactData }
            initLinkedItem={ initLinkedItem }
            names={ names }
            noteContactOptions={ noteContactOptions || [] }
            onEntityIdClick={ onEntityIdClick }
            onLinkedItemChange={ onLinkedItemChange }
            setValue={ setValue }
            taskId={ editNote.linkedItemId }
          />
        }
      </FieldsRow>
      <TextEditorWrapper>
        <NoteText
          clearErrors={ clearErrors }
          control={ control }
          errors={ errors }
          names={ names }
        />
      </TextEditorWrapper>
      <TagsWrapper>
        <Tags
          control={ control }
          initTags={ editNote?.tags || [] }
          name={ names.tags }
          predefinedTags={ predefinedTags }
          setValue={ setValue }
        />
      </TagsWrapper>
      <ButtonWrapper>
        <SaveButton
          control={ control }
          noteTextInputName={ names.noteText }
          noteTitleInputName={ names.title }
          onSaveClick={ onSaveClick }
          title={ title }
        />
      </ButtonWrapper>

    </Wrapper>
  );
};
