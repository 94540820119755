import React, { useCallback, MouseEvent } from 'react';
import { useStore } from '@store';

import InfoIcon, { INFO_COLOR_SCHEME, INFO_ICON_TYPES } from '@components/InfoIcon';
import UserItem from '@components/NewUserItem';

import { LinkedContactCellProps } from './types';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';

import { InfoIconWrapper, StyledTableCell, Wrapper } from './styles';

export const LinkedContactCell = ({
  $isClickable = true,
  alternativeClickHandler,
  children,
  contact,
  infoIconClick,
  isInfoIconVisible = false,
  placeholderContent = '-',
  tab,
  UserItemProps,
}: LinkedContactCellProps) => {
  const routerStore = useStore().RouterStore;
  const { isContactsNamesReversed } = useStore().SettingsStore;

  const onClick = useCallback(() => {
    if(alternativeClickHandler){
      alternativeClickHandler();
      return;
    }
    if(!$isClickable || !contact) {
      return;
    }
    routerStore.moveToContact({
      id: contact.id,
      contactType: contact.type,
      ...tab ? { tab } : { tab: CONTACT_DETAILS_TAB.overview },
      subTabProps: {
        contactType: contact.type,
      }
    });
  }, [alternativeClickHandler, $isClickable, contact, routerStore, tab]);

  const infoIconClickWrapper = useCallback((event: MouseEvent<HTMLDivElement>) => {
    if(infoIconClick) {
      event.stopPropagation();
      infoIconClick();  
    }
  }, [infoIconClick]);

  return (
    <StyledTableCell
      $isClickable={ $isClickable }
      onClick={ onClick }
      padding={ 'none' }
    >
      <Wrapper>
        {
          contact
            ? (
              <UserItem
                innerPadding={ '0px' }
                reverseNames={ isContactsNamesReversed }
                user={ contact }
                withCoreClassification={ true }
                { ...UserItemProps }
              />
            )
            : placeholderContent
        }
        {children}
        { isInfoIconVisible && infoIconClick && 
          <InfoIconWrapper onClick={ infoIconClickWrapper }>
            <InfoIcon
              colorScheme={ INFO_COLOR_SCHEME.transparentAlert }
              disabled={ false }
              helpText={ '' }
              type={ INFO_ICON_TYPES.alertInfo }
              wrapperProps={ {
                marginLeft: 'auto',
                paddingRight: '10px'
              } }
            />
          </InfoIconWrapper>
        }
      </Wrapper>
    </StyledTableCell>
  );
};
