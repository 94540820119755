import styled from 'styled-components';
import { COLORS } from '@constants/colors';

import { BOX_SHADOW } from '@constants/style';

import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: relative;
  padding: 11px 10px 11px 12px;
  border-radius: 6px;
  background-color: ${COLORS.white};
  overflow: hidden;
  
  box-shadow: ${BOX_SHADOW};
  
  &:after{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: ${({ $indicatorColor }) => $indicatorColor || 'transparent'};
  }
`;


export const AvatarWrapper = styled.div`
  margin-right: 6px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

export const InfoFirstRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-column-gap: 8px;
  align-items: baseline;
`;

export const InfoSecondRow = styled.div`
  display: flex;
  gap: 2px;
  align-items: baseline;
  
  margin-top: auto;
  color: ${COLORS.helpText};
  font-size: 10px;
`;


export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  
  width: 20px;
  margin-left: 8px;
`;

export const TicketInfoWrapper = styled.div`
  display: flex;
`;
